import { ASSETS_URL } from '../icons'

const GALLERY_PHOTOS = [
  `${ASSETS_URL}/us/our-team/gallery/image1.png`,
  `${ASSETS_URL}/us/our-team/gallery/image2.jpg`,
  `${ASSETS_URL}/us/our-team/gallery/image3.png`,
  `${ASSETS_URL}/us/our-team/gallery/image4.png`,
  `${ASSETS_URL}/us/our-team/gallery/image5.png`,
  `${ASSETS_URL}/us/our-team/gallery/image6.png`,
  `${ASSETS_URL}/us/our-team/gallery/image7.jpg`,
  `${ASSETS_URL}/us/our-team/gallery/image8.png`,
  `${ASSETS_URL}/us/our-team/gallery/image9.png`,
  `${ASSETS_URL}/us/our-team/gallery/image10.jpg`,
  `${ASSETS_URL}/us/our-team/gallery/image11.png`
]

export default GALLERY_PHOTOS
