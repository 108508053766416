import React, { useRef } from 'react'

import { HOW_WORKS_BACK_IMAGES, HOW_WORKS_STEPS } from '../../../constants/icons'
import useIsInViewport from '../../../hooks/useIsInViewPort'

import Step from './Step'

const HowItWorks = () => {
  const reference = useRef(null)
  const isInViewPort = useIsInViewport(reference)

  return (
  <div>
    <h1 ref={reference} className={`my-8 text-center font-poppins text-3xl font-bold text-pinflag-dark ${isInViewPort ? 'animate-side-appear': 'opacity-0' }`}>
      ¿Cómo <span className="text-pinflag">funciona?</span>
    </h1>
    <Step
      stepNumber={1}
      windowImage={HOW_WORKS_STEPS.step1}
      circle={HOW_WORKS_BACK_IMAGES.purpleCircle}
      title="Checkout"
      text="El cliente elige sus productos y pasa al checkout Pinflag, un paso simple y rápido donde se validará la información del consumidor, que tomará menos tiempo que un checkout común."
      line
    />
    <Step
      stepNumber={2}
      windowImage={HOW_WORKS_STEPS.step2}
      circle={HOW_WORKS_BACK_IMAGES.calypsoCircle}
      title="Opción de despacho"
      text="Después de completar los datos, el cliente escoge el método de envío que más le acomode, ya sea envío a domicilio, retiro en tienda o puntos pick-up."
      line
      reverse
    />
    <Step
      stepNumber={3}
      windowImage={HOW_WORKS_STEPS.step3}
      circle={HOW_WORKS_BACK_IMAGES.grayCircle}
      title="Pago"
      text="El cliente paga los productos con el método de pago que prefiera y finaliza la compra."
      line
    />
    <Step
      stepNumber={4}
      windowImage={HOW_WORKS_STEPS.step4}
      circle={HOW_WORKS_BACK_IMAGES.purpleCircle}
      title="Despacho"
      text="Con la compra exitosa comienza el proceso de envío. Se mantiene informado en cada momento gracias a las notificaciones y el portal de seguimiento."
      reverse
    />
  </div>
)}

export default HowItWorks
