import React from 'react'

const ServicesHero = ({ content, step }) => (
  <div
    key={step}
    className={`
          ${content.background}
          w-full items-center
          bg-[length:120%_100%] 
          bg-no-repeat 
          md:flex
          md:h-[78vh]
          md:bg-[length:100%_80%]
          lg:justify-end lg:bg-[length:100%_80%] xl:bg-[length:100%_100%]
          `}
  >
    <div className="flex animate-side-appear flex-col items-start justify-between p-8 md:ml-8 md:mb-24 lg:ml-14 lg:mb-36 lg:w-1/3">
      <div className="my-4 font-poppins text-2xl font-bold leading-8 text-white lg:text-3xl lg:leading-[3rem] xl:text-4xl xl:leading-[3.5rem]">
        {content.title}{' '}
        <div className="leading-10 text-pinflag-dark xl:leading-[3.5rem]">
          {content.highlightedTitle}
        </div>
      </div>
      <div className="my-4 text-sm text-white lg:text-base">{content.body}</div>
    </div>
    <img
      src={content.desktopImage}
      alt={content.name}
      className="m-auto hidden w-4/5 animate-slow-grow-appear sm:w-2/3 md:my-8 md:inline-block md:w-1/2"
    />
    <img
      src={content.cellphone}
      alt={content.name}
      className="m-auto mb-8 w-[86%] animate-slow-grow-appear sm:w-4/5 md:hidden"
    />
    <div className="flex flex-wrap">
      <img
        src={content.circle1}
        alt={content.name}
        className="m-auto mb-8 w-[14rem] animate-grow-appear px-4 md:hidden"
      />
      <img
        src={content.circle2}
        alt={content.name}
        className="m-auto mb-8 w-[14rem] animate-grow-appear px-4 md:hidden"
      />
    </div>
  </div>
)

export default ServicesHero
