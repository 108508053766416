import React from 'react'

import Hero from '../components/Home/Hero'
import HowItWorks from '../components/Home/HowItWorks/HowItWorks'
import ClientsCounter from '../components/Home/ClientsCounter/ClientsCounter'
import ClientsBenefits from '../components/Home/ClientsBenefits'
import CMS from '../components/Home/CMS'
// import HowToStart from '../components/Home/HowToStart'

const Home = () => (
    <div>
        <Hero />
        <HowItWorks />
        <ClientsCounter />
        <CMS />
        <ClientsBenefits />
        {/* <div className="bg-home-bottom bg-cover bg-top">
          <HowToStart />
        </div> */}
    </div>
  )

export default Home
