import React, { useState } from 'react'

import { HOW_WORKS_BACK_IMAGES, TEAM_ICONS } from '../../../constants/icons'

const ProfileCard = ({
  image,
  name,
  position,
  linkedin,
  description,
  activeShowDetails,
  highlight,
  quote }) => {
  const [showDetails, setShowDetails] = useState(false)

  return (
    <div
      className={`relative mx-8 my-20 h-44 w-52 ${showDetails && 'z-10'}`}
      onMouseEnter={() => (activeShowDetails ? setShowDetails(true) : undefined)}
      onMouseLeave={() => setShowDetails(false)}
    >
      <img
          src={HOW_WORKS_BACK_IMAGES.calypsoCircle}
          alt="circulo"
          className={`absolute top-[-7.5rem] z-0 transition-all duration-700 ${showDetails? 'opacity-100': 'scale-50 opacity-0'}`}
        />
      <div
        className={`
          rounded-2xl bg-white pt-16 text-center text-sm text-pinflag-dark transition-all 
          duration-700 
          ${
          showDetails ? 'h-fit drop-shadow-2xl' : ' h-44 drop-shadow-xl'
        }`}
      >
        <div className={`px-10 ${showDetails ? 'pb-4' : 'pb-10'}`}>
          <img
            src={image}
            alt="profile"
            className="absolute top-[-4.3rem] mx-2 w-28 rounded-full"
          />
          <div className="whitespace-nowrap font-semibold">{name}</div>
          <div className="whitespace-nowrap">{position}</div>
          <div onClick={linkedin === '' ? undefined : () =>  window.open(linkedin)} role="button" tabIndex="0">
            <img src={TEAM_ICONS.linkedin} alt="linkedin" className="mx-auto mt-4 w-6" />
          </div>
        </div>
        {showDetails && (
          <div>
            <div className="animate-fast-grow-appear p-4 px-6 pt-0 pb-6 text-xs">&quot;{description}&quot;</div>
            <div className="animate-fast-grow-appear p-4 px-6 pt-0 pb-6 text-xs font-bold">{highlight ? `Dato Freak: ${highlight}` : `Frase Típica: ${quote}`}</div>
          </div>
        )}
      </div>
    </div>
  )
}

export default ProfileCard
