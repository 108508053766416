import React, { useRef } from 'react'

import useIsInViewport from '../../hooks/useIsInViewPort'
import CMS_LOGOS from '../../constants/cms'

const CMS = () => {
  const reference = useRef(null)
  const isInViewPort = useIsInViewport(reference)

  const reference1 = useRef(null)
  const isInViewPort1 = useIsInViewport(reference)

  return (
    <div ref={reference} className="my-20 w-full text-center">
      <div
        className={`mb-10 font-poppins text-3xl font-bold text-pinflag-dark ${
          isInViewPort ? 'animate-side-appear' : 'opacity-0'
        }`}
      >
        Integra tu <span className="text-pinflag">e-commerce</span>
      </div>
      <div className="mx-8 flex flex-wrap justify-evenly gap-20">
        {CMS_LOGOS.map((cms) => (
          <img
            ref={reference1}
            src={cms}
            key={cms}
            alt={cms}
            className={`h-auto w-48 ${isInViewPort1 ? 'animate-grow-appear' : 'opacity-0'}`}
          />
        ))}
      </div>
    </div>
  )
}

export default CMS
