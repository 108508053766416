import { ASSETS_URL } from './icons'

export const NAVBAR_SERVICES = [
  {
    name: 'Checkout',
    icon: `${ASSETS_URL}/navbar/checkout.png`
  },
  {
    name: 'PickUp',
    icon: `${ASSETS_URL}/navbar/pickup.png`
  },
  {
    name: 'Omnicanalidad',
    icon: `${ASSETS_URL}/navbar/omnichannel.png`
  },
  {
    name: 'Etiquetas',
    icon: `${ASSETS_URL}/navbar/labels.png`
  },
  {
    name: 'Trazabilidad',
    icon: `${ASSETS_URL}/navbar/traceability.png`
  }
]

export const NAVBAR_US = [
  {
    name: 'Nuestro equipo',
    icon: `${ASSETS_URL}/navbar/team.png`
  },
  {
    name: 'Nuestra empresa',
    icon: `${ASSETS_URL}/navbar/company.png`
  },
  {
    name: 'Noticias',
    icon: `${ASSETS_URL}/navbar/news.png`
  }
]
