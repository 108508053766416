import React from 'react'
import { useNavigate } from 'react-router-dom'

const DropDownItem = ({ pathname, search, name, icon }) => {
  const navigate = useNavigate()

  const goToAddress = () => {
    navigate({ pathname, search })
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  return (
    <button
      onClick={goToAddress}
      className="my-4 flex w-full text-center hover:opacity-70"
      type="button"
    >
      <img src={icon} className="mr-2 h-auto w-5" alt={name} />
      {name}
    </button>
  )
}

export default DropDownItem
