import { ASSETS_URL, HOW_WORKS_BACK_IMAGES } from '../icons'

const SERVICES_STEPS = [
  [
    {
      windowImage: `${ASSETS_URL}/services/services-explanation/checkout/step-1.png`,
      title1:'Facilítales la vida',
      title2:'a tus compradores',
      text:'Disminuye la fricción de tu checkout. Pide solo los datos esenciales de una forma más amigable.',
      line: true,
      reverse: false
    },
    {
      windowImage: `${ASSETS_URL}/services/services-explanation/checkout/step-2.png`,
      title1:'Evita rellenar tus datos',
      title2:'una y otra vez',
      text:'Con el autorelleno de datos simplifica la compra a tus clientes. Evita rellenar los datos en cada compra y ofrece la opción de guardarlos para futuras visitas.',
      line: true,
      reverse: true
    },
    {
      windowImage: `${ASSETS_URL}/services/services-explanation/checkout/step-3.png`,
      title1:'Que tus clientes elijan',
      title2:'la modalidad de envío',
      text:'Ofrecer variedad de modalidades de envíos es clave para el éxito de compra. Con Pinflag, además de ofrecer tus tiendas y puntos, ofrece diferentes tipos de entrega a tus clientes.',
      line: true,
      reverse: false
    },
    {
      windowImage: `${ASSETS_URL}/services/services-explanation/checkout/step-4.png`,
      title1:'Tu check-out',
      title2:'a tu gusto',
      text:'Evita los check-out nativos de cada CMS y genera una sintonía con tu página en todo momento. Con Pinflag puedes modificar el color y textos para mantener la línea gráfica en todo momento.',
      line: false,
      reverse: true
    }
  ],
  [
    {
      windowImage: `${ASSETS_URL}/services/services-explanation/points/step-1.png`,
      title1:'Ofrece más de 2.000',
      title2:'puntos de retiro',
      text:'Tenemos puntos a lo largo de todo chile, para que no pierdas ninguna venta producto del método de envío.',
      line: true,
      reverse: false
    },
    {
      windowImage: `${ASSETS_URL}/services/services-explanation/points/step-2.png`,
      title1:'Precios de envío',
      title2:'más convenientes',
      text:'Hasta 40% de descuento en la tarifa para los pedidos a los puntos de retiro. Evita caídas de compra por precios de envío muy altos.',
      line: true,
      reverse: true,
      specialLine: 'h-[70%] xl:h-[56%]'
    },
    {
      windowImage: `${ASSETS_URL}/services/services-explanation/points/step-3.png`,
      title1:'Disminuye la huella',
      title2:'de carbono',
      text:'Al enviar pedidos a puntos de pick-up, se genera una centralización y optimización. Más pedidos pueden ser entregados por una menor cantidad de camiones.',
      line: true,
      reverse: false,
      specialLine:'h-[77%] xl:h-[68%]'
    },
    {
      windowImage: `${ASSETS_URL}/services/services-explanation/points/step-4.png`,
      title1:'Elige al transportista',
      title2:'que tu prefieras',
      text:'Tenemos integración con 7 transportistas, elige el o los que más se acomoden a tus exigencias.',
      line: false,
      reverse: true
    }
  ],
  [
    {
      windowImage: `${ASSETS_URL}/services/services-explanation/omnichannel/step-1.png`,
      title1:'Aumenta tus ventas',
      title2:'de forma más simple',
      text:'Ofreciendo retiro en tienda, genera flujo en tus puntos físicos y aumenta la probabilidad de venta cruzada.',
      specialLine:'h-[77%] xl:h-[60%]',
      line: true,
      reverse: false
    },
    {
      windowImage: `${ASSETS_URL}/services/services-explanation/omnichannel/step-2.png`,
      title1:'Elige si usar el stock',
      title2:'de tiendas o bodega',
      text:'Disminuye los problemas por stock ofreciendo los productos tanto de la bodega como de las tiendas.',
      line: true,
      reverse: true,
      specialLine:'h-[77%] xl:h-[68%]'
    },
    {
      windowImage: `${ASSETS_URL}/services/services-explanation/omnichannel/step-3.png`,
      title1:'Aumenta la eficiencia',
      title2:'de tus operadores',
      text:'Ordena todos los pedidos para retiro con nuestra APP y entrégalos en segundos. Nuestra tecnología permite organizar y tener en todo momento la posición exacta de cada paquete.',
      line: false,
      reverse: false
    }
  ],
  [
    {
      windowImage: `${ASSETS_URL}/services/services-explanation/labels/step-1.png`,
      title1:'Imprime etiquetas de',
      title2:'forma unitaria o masiva',
      text:'Conectamos nuestro API a tu WMS, generando una integración automática a la hora de imprimir etiquetas o puedes hacer de manera manual, a través de nuestro administrador.',
      specialLine:'h-[85%] xl:h-[60%]',
      line: true,
      reverse: false
    },
    {
      windowImage: `${ASSETS_URL}/services/services-explanation/labels/step-2.png`,
      title1:'Más de 7 opciones',
      title2:'de transportistas',
      text:'Nuestra integración con cada uno de estos, permite elegir el que más te acomode, ya sea por precio o nivel de servicio. Podrás configurar tus propias condiciones de precio de cara al cliente.',
      line: false,
      reverse: true
    }
  ],
  [
    {
      windowImage: `${ASSETS_URL}/services/services-explanation/traceability/step-1.png`,
      title1:'Notificaciones',
      title2:'de seguimiento',
      text:'Mejora la experiencia de tus clientes con el envío automático de correos de seguimiento. 9 de cada 10 usuarios abren este correo.',
      specialLine:'h-[95%] xl:h-[67%]',
      line: true,
      reverse: false
    },
    {
      windowImage: `${ASSETS_URL}/services/services-explanation/traceability/step-2.png`,
      title1:'Seguimiento completo,',
      title2:'detallado y actualizado',
      text:'Gracias a nuestra tecnología de inteligencia artificial, le ofrecemos al cliente una experiencia más completa al darle información aproximada de la fecha de entrega de su pedido, actualizándolo en caso de inconvenientes.',
      line: true,
      reverse: true
    },
    {
      windowImage: `${ASSETS_URL}/services/services-explanation/traceability/step-3.png`,
      title1:'Disminuye las llamadas',
      title2:'a tu servicio al cliente',
      text:'Cerca del 60% de las llamadas a servicio al cliente son por el estado del pedido. Al insertar el portal de seguimiento dentro de tu página y con los correos de seguimiento, evitas un gran porcentaje de estos llamados.',
      line: true,
      reverse: false
    },
    {
      windowImage: `${ASSETS_URL}/services/services-explanation/traceability/step-4.png`,
      title1:'Aumenta la eficiencia',
      title2:'en tu equipo de soporte',
      text:'Con nuestro portal de administración podrás revisar los estados de los pedidos, ver con anticipación si tienen fallas, gestionar los envíos y cambiar condiciones. Además puede mantener y controlar las métricas más relevantes de tu negocio.',
      line: false,
      reverse: true
    }
  ]
]

export const BACKGROUND_CIRCLES = [
  {
    circle1: {
      image: HOW_WORKS_BACK_IMAGES.calypsoCircle,
      position: 'hidden absolute top-36 left-[-200px] z-0 w-96 md:inline-block'
    },
    circle2: {
      image: HOW_WORKS_BACK_IMAGES.purpleCircle,
      position: 'hidden absolute bottom-24 right-[-200px] z-0 w-96 md:inline-block'
    }
  },
  {
    circle1: {
      image: HOW_WORKS_BACK_IMAGES.purpleCircle,
      position: 'hidden absolute bottom-24 left-[-200px] z-0 w-96 md:inline-block'
    },
    circle2: {
      image: HOW_WORKS_BACK_IMAGES.grayCircle,
      position: 'hidden absolute top-36 right-[-200px] z-0 w-96 md:inline-block'
    }
  },
  {
    circle1: {
      image: HOW_WORKS_BACK_IMAGES.grayCircle,
      position: 'hidden absolute top-36 left-[-200px] z-0 w-96 md:inline-block'
    },
    circle2: {
      image: HOW_WORKS_BACK_IMAGES.calypsoCircle,
      position: 'hidden absolute bottom-24 right-[-200px] z-0 w-96 md:inline-block'
    }
  },
  {
    circle1: {
      image: HOW_WORKS_BACK_IMAGES.calypsoCircle,
      position: 'hidden absolute bottom-24 left-[-200px] z-0 w-96 md:inline-block'
    },
    circle2: {
      image: HOW_WORKS_BACK_IMAGES.purpleCircle,
      position: 'hidden absolute top-36 right-[-200px] z-0 w-96 md:inline-block'
    }
  },
  {
    circle1: {
      image: HOW_WORKS_BACK_IMAGES.purpleCircle,
      position: 'hidden absolute top-36 left-[-200px] z-0 w-96 md:inline-block'
    },
    circle2: {
      image: HOW_WORKS_BACK_IMAGES.grayCircle,
      position: 'hidden absolute bottom-24 right-[-200px] z-0 w-96 md:inline-block'
    }
  }
]

 export default SERVICES_STEPS
