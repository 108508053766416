import React from 'react'

import { LOGOS, pinflagWhite, RRSS_ICONS } from '../constants/icons'

const Footer = () => (
  <div className="w-full items-center bg-pinflag-dark p-4 pb-8 text-sm text-white md:pb-4">
    <div className="justify-between md:flex">
      <div className="md:ml-4">
        <img src={pinflagWhite} alt="Logo" className="m-auto mt-0 w-24 md:ml-0 md:w-28" />
      </div>
      <div className="flex flex-col gap-8 md:ml-10 md:flex-row">
          <img src={LOGOS.startupChile} alt="StartUp Chile" className="m-auto w-40 md:ml-0 md:w-44" />
          <img src={LOGOS.ccs} alt="Camara de Comercio" className="m-auto mb-10 w-40 md:mb-auto md:ml-0 md:w-44" />
        </div>
      <div className="flex flex-col items-center gap-y-3.5 md:m-4 md:w-1/4 md:items-end">
        <div className="flex flex-col justify-center text-center md:pt-5 md:text-right">
          <h2 className="text-base md:ml-auto md:mr-2">¿Dudas?</h2>
          <span className="text-xs md:mr-2">contacto@pinflag.cl</span>
          <span className="text-xs md:mr-2">+569 4563 0114</span>
        </div>
        <div className="my-4 mb-7 flex h-5 w-full flex-row justify-center md:mb-[-1.3rem] md:justify-end">
          <a href="https://www.instagram.com/pinflag_chile/?hl=es-la">
            <img src={RRSS_ICONS.instagram} alt="Instagram" className="mx-2 w-5" />
          </a>
          <a href="https://cl.linkedin.com/company/pinflag">
            <img src={RRSS_ICONS.linkedin} alt="Linkedin" className="mx-2 w-5" />
          </a>
        </div>
      </div>
    </div>
    <div className="relative text-center text-xs md:bottom-[25px] md:ml-4 md:text-left">
      El Gobernador 20, Providencia
    </div>
    <div className="relative text-center text-xs md:bottom-[20px] md:ml-4 md:text-left">
      &copy; Incutech SpA. 2022 Pinflag Chile. Todos los derechos reservados.
    </div>
  </div>
)

export default Footer
