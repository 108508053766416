import React, { useState } from 'react'

import { downArrow } from '../../constants/icons'

const DropDownButtonMobile = ({ text, children }) => {
  const [dropDownOpen, setDropDownOpen] = useState(false)
  return (
    <div className="flex cursor-pointer items-center justify-center py-4">
      <div
        onClick={() => setDropDownOpen(!dropDownOpen)}
        role="button"
        tabIndex="0"
      >
        <div className="mb-2 flex items-center justify-center gap-4">
          <span className={dropDownOpen && 'font-semibold text-pinflag'}>{text}</span>
          <img className={`w-2 transition ${dropDownOpen && 'rotate-180'}`} src={downArrow} alt={text} />
        </div>
        {dropDownOpen && (
          <div className="flex w-screen flex-col text-center opacity-70">
            {children}
          </div>
        )}
      </div>
    </div>
  )
}

export default DropDownButtonMobile
