const ROUTES = {
  HOME: '/',
  SERVICES: '/servicios',
  PLANS: '/planes',
  PARTNERS: '/partners',
  BLOG: '/blog',
  BLOG_VIEW: '/blog/view',
  OUR_TEAM: '/nosotros'
}

export default ROUTES
