import React, { useRef } from 'react'

import useIsInViewport from '../../hooks/useIsInViewPort'

import StepServices from './StepServices'

const ServiceExplanation = ({ content, circles }) => {
  const referenceCircle1 = useRef(null)
  const isInViewPortCircle1 = useIsInViewport(referenceCircle1)

  const referenceCircle2 = useRef(null)
  const isInViewPortCircle2 = useIsInViewport(referenceCircle2)

  return (
    <div className="relative mb-24 overflow-hidden md:mt-14">
      <div>
        {content.map((contentItem) => (
          <StepServices
            key={contentItem.text}
            windowImage={contentItem.windowImage}
            title1={contentItem.title1}
            title2={contentItem.title2}
            text={contentItem.text}
            specialLine={contentItem.specialLine}
            line={contentItem.line}
            reverse={contentItem.reverse}
          />
        ))}
      </div>
      <img
        src={circles.circle1.image}
        ref={referenceCircle1}
        alt="circle"
        className={`${circles.circle1.position} ${isInViewPortCircle1 ? 'animate-grow-appear' : 'opacity-0'}`}
      />
      <img
        src={circles.circle2.image}
        ref={referenceCircle2}
        alt="circle"
        className={`${circles.circle2.position} ${isInViewPortCircle2 ? 'animate-grow-appear' : 'opacity-0'}`}
      />
    </div>
  )
}

export default ServiceExplanation
