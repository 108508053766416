export const ASSETS_URL = process.env.REACT_APP_ASSETS_URL

export const HOW_WORKS_STEPS = {
  step1: `${ASSETS_URL}/home/how-it-works/step-1.svg`,
  step2: `${ASSETS_URL}/home/how-it-works/step-2.svg`,
  step3: `${ASSETS_URL}/home/how-it-works/step-3.webp`,
  step4: `${ASSETS_URL}/home/how-it-works/step-4.svg`
}

export const HOW_WORKS_BACK_IMAGES = {
  calypsoCircle: `${ASSETS_URL}/home/background/calypso-circle.svg`,
  purpleCircle: `${ASSETS_URL}/home/background/purple-circle.svg`,
  grayCircle: `${ASSETS_URL}/home/background/gray-circle.svg`,
  line1: `${ASSETS_URL}/home/how-it-works/lines.png`,
  line2: `${ASSETS_URL}/home/how-it-works/lines-2.png`
}

export const CLIENT_RECOMMEND_US = {
  group1: `${ASSETS_URL}/home/clients-recommend-us/Grupo1.png`,
  group2: `${ASSETS_URL}/home/clients-recommend-us/Grupo2.png`,
  group3: `${ASSETS_URL}/home/clients-recommend-us/Grupo3.png`,
  group4: `${ASSETS_URL}/home/clients-recommend-us/Grupo4.png`
}

export const RRSS_ICONS = {
  instagram: `${ASSETS_URL}/rrss/instagram.png`,
  facebook: `${ASSETS_URL}/rrss/facebook.png`,
  youtube: `${ASSETS_URL}/rrss/youtube.png`,
  linkedin: `${ASSETS_URL}/rrss/linkedin.png`
}

export const SERVICES_EXPLANATION_IMAGES = {
  line1: `${ASSETS_URL}/services/services-explanation/lines/line-1.svg`,
  line2: `${ASSETS_URL}/services/services-explanation/lines/line-2.svg`
}

export const TEAM_ICONS = {
  ourTeam: `${ASSETS_URL}/us/our-team/our-team-top.png`,
  linkedin: `${ASSETS_URL}/us/our-team/linkedin.png`,
  arrow: `${ASSETS_URL}/us/our-team/arrow.webp`
}

export const LOGOS = {
  ccs: `${ASSETS_URL}/logos/ccs-logo.png`,
  startupChile: `${ASSETS_URL}/logos/startupchile-logo.png`
}

export const pin = `${ASSETS_URL}/home/how-it-works/pin.svg`

export const downArrow = `${ASSETS_URL}/general/down-arrow.svg`

export const pinflagLogo = `${ASSETS_URL}/logos/pinflag-logo.webp`

export const pinflagWhite = `${ASSETS_URL}/logos/pinflag-white.png`

export const arrowRight = `${ASSETS_URL}/services/hero/arrow.png`

export const cellPhone = `${ASSETS_URL}/home/cell-phone.webp`

export const close = `${ASSETS_URL}/general/close.png`

export const bars = `${ASSETS_URL}/general/bars.png`

