import React, { useRef } from 'react'

import { CLIENT_RECOMMEND_US } from '../../constants/icons'
import useIsInViewport from '../../hooks/useIsInViewPort'

const ClientsBenefits = () => {
  const reference = useRef(null)
  const isInViewPort = useIsInViewport(reference)

  const reference1 = useRef(null)
  const isInViewPort1 = useIsInViewport(reference1)

  const reference2 = useRef(null)
  const isInViewPort2 = useIsInViewport(reference2)

  const reference3 = useRef(null)
  const isInViewPort3 = useIsInViewport(reference3)

  const reference4 = useRef(null)
  const isInViewPort4 = useIsInViewport(reference4)

  return (
  <div ref={reference} className="my-20 w-full text-center">
    <div className={`mb-10 font-poppins text-3xl font-bold text-pinflag ${isInViewPort ? 'animate-side-appear': 'opacity-0'}`}>
      Nuestros clientes <span className="text-pinflag-dark">nos recomiendan</span>
    </div>
    <div className="m-auto mb-16 flex w-fit flex-col gap-20 md:flex-row">
      <div className="flex flex-col gap-20 lg:flex-row">
        <img ref={reference1} src={CLIENT_RECOMMEND_US.group1} alt="grupo1" className={`w-52 ${isInViewPort1 ? 'animate-grow-appear': 'opacity-0'}`}/>
        <img ref={reference2} src={CLIENT_RECOMMEND_US.group2} alt="grupo2" className={`w-52 ${isInViewPort2 ? 'animate-grow-appear': 'opacity-0'}`}/>
      </div>
      <div className="flex flex-col gap-20 lg:flex-row">
        <img ref={reference3} src={CLIENT_RECOMMEND_US.group3} alt="grupo3" className={`w-52 ${isInViewPort3 ? 'animate-grow-appear' : 'opacity-0'}`}/>
        <img ref={reference4} src={CLIENT_RECOMMEND_US.group4} alt="grupo4" className={`w-52 ${isInViewPort4 ? 'animate-grow-appear' : 'opacity-0'}`}/>
      </div>
    </div>
  </div>
)}

export default ClientsBenefits
