import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

import NavBar from './components/NavBar/NavBar'
import Footer from './components/Footer'
import './App.css'
import ROUTES from './constants/routes'
import Home from './pages/Home'
import Services from './pages/Services'
import OurTeam from './pages/Us/OurTeam'

const App = () => (
  <div className="bg-background font-inter">
    <BrowserRouter>
      <NavBar />
      <Routes>
        <Route path={ROUTES.HOME} element={<Home />} />
        <Route path={ROUTES.SERVICES} element={<Services />} />
        <Route path={ROUTES.OUR_TEAM} element={<OurTeam />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  </div>
)

export default App
