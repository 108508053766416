
const TAB_BAR_STEPS = [
  {
    step: 0,
    text: 'Check-out',
    color: 'bg-pinflag'
  },
  {
    step: 1,
    text: 'Puntos Pick-up',
    color: 'bg-pinflag-dark'
  },
  {
    step: 2,
    text: 'Omnicanalidad',
    color: 'bg-purple'
  },
  {
    step: 3,
    text: 'Creador de etiquetas',
    color: 'bg-pinflag'
  },
  {
    step: 4,
    text: 'Trazabilidad',
    color: 'bg-pinflag-dark'
  }
]

export default TAB_BAR_STEPS
