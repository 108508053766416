import { ASSETS_URL } from '../icons'

const SERVICES_HERO_CONTENT = [
  {
    name: 'checkout',
    background: 'bg-calypso-top',
    title: 'Un checkout más',
    highlightedTitle: 'rápido y simplificado',
    body: 'Contamos con un checkout mucho más amigable con el cliente, haciendo que el proceso sea rápido y eficiente. Gracias a esto, la tasa de conversión puede aumentar considerablemente.',
    desktopImage: `${ASSETS_URL}/services/hero/checkout/desktop-image.png`,
    cellphone: `${ASSETS_URL}/services/hero/checkout/portrait.png`,
    circle1: `${ASSETS_URL}/services/hero/checkout/circle-1.png`,
    circle2: `${ASSETS_URL}/services/hero/checkout/circle-2.png`
  },
  {
    name: 'points',
    background: 'bg-points-top-mobile md:bg-points-top',
    title: 'Elige el punto',
    highlightedTitle: 'que más te acomode',
    body: 'Con nuestra plataforma, podrás ofrecerle a tus clientes tanto envíos a domicilio, como retiros en tienda y más de 2.000 puntos de retiro alrededor de todo Chile.',
    desktopImage: `${ASSETS_URL}/services/hero/points/desktop-image.png`,
    cellphone: `${ASSETS_URL}/services/hero/points/portrait.png`,
    circle1: `${ASSETS_URL}/services/hero/points/circle-1.png`,
    circle2: `${ASSETS_URL}/services/hero/points/circle-2.png`
  },
  {
    name: 'omnichannel',
    background: 'bg-purple-top',
    title: 'Soluciones sencillas',
    highlightedTitle: 'que facilitan tus ventas',
    body: 'Entrega una experiencia omnicanal a tus clientes, comprar en línea y retirar en tienda. Mantén conectados todos tus puntos físicos y súmalos a la red de Pinflag.',
    desktopImage: `${ASSETS_URL}/services/hero/omnichannel/desktop-image.png`,
    cellphone: `${ASSETS_URL}/services/hero/omnichannel/portrait.png`,
    circle1: `${ASSETS_URL}/services/hero/omnichannel/circle-1.png`,
    circle2: `${ASSETS_URL}/services/hero/omnichannel/circle-2.png`
  },
  {
    name: 'labels',
    background: 'bg-calypso-top',
    title: 'Optimiza tu rendimiento',
    highlightedTitle: 'operacional',
    body: 'Ahorra tiempos y costos con tu logística y mejora la experiencia con las integraciones nativas de los transportistas.',
    desktopImage: `${ASSETS_URL}/services/hero/labels/desktop-image.png`,
    cellphone: `${ASSETS_URL}/services/hero/labels/portrait.png`,
    circle1: `${ASSETS_URL}/services/hero/labels/circle-1.png`,
    circle2: `${ASSETS_URL}/services/hero/labels/circle-2.png`
  },
  {
    name: 'traceability',
    background: 'bg-gray-top',
    title: 'Mejora la experiencia',
    highlightedTitle: 'de tus clientes',
    body: 'Ofréceles a tus clientes un seguimiento completo de su pedido. Con nuestro portal de seguimiento a tu medida, podrás disminuir las llamadas y ahorrar tiempo.',
    desktopImage: `${ASSETS_URL}/services/hero/traceability/desktop-image.png`,
    cellphone: `${ASSETS_URL}/services/hero/traceability/portrait.png`,
    circle1: `${ASSETS_URL}/services/hero/traceability/circle-1.png`,
    circle2: `${ASSETS_URL}/services/hero/traceability/circle-2.png`
  }
]

export default SERVICES_HERO_CONTENT
