import React from 'react'

import TAB_BAR_STEPS from '../../constants/general'

const TabBar = ({ step, setStep }) => {
  const handleClick = (event) => {
    setStep(parseInt(event.target.value, 10))
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }
  return (
    <div className="z-20 m-auto flex w-9/12 flex-col justify-around rounded-lg bg-white px-8 py-4 drop-shadow-lg md:sticky md:top-24 md:w-full md:flex-row md:rounded-full lg:w-9/12">
      {TAB_BAR_STEPS.map((currentStep) => (
        <button
          className={`cursor-pointer rounded-full px-4 py-1 font-semibold text-white
          hover:opacity-70 
          ${
            currentStep.step === step ? currentStep.color : 'bg-white text-pinflag-dark'
          } md:text-sm lg:text-base`}
          key={currentStep.step}
          onClick={handleClick}
          value={currentStep.step}
          type="button"
        >
          {currentStep.text}
        </button>
      ))}
    </div>
  )
}

export default TabBar
