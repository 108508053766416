import { ASSETS_URL } from '../icons'

const TEAM = [
  {
    image: `${ASSETS_URL}/us/our-team/team/Cata_Alvarez.webp`,
    name: 'Catalina Alvarez',
    position: 'Software Engineer',
    linkedin: 'https://www.linkedin.com/in/cataalvarez/',
    description: 'La Cata es Ingeniera Civil Industrial en Computación de la Universidad Católica y siempre le gustaron las matemáticas. Hoy es Ingeniera de Software y su sueño es no vivir para trabajar si no que trabajar para vivir. Le gusta compartir con sus mascotas en sus tiempos libres y a veces bailar.',
    freakFact: 'Tiene 2 perros y 6 gatos.',
    showDetails: true
  },
  {
    image: `${ASSETS_URL}/us/our-team/team/Cristobal_Delano.webp`,
    name: 'Cristóbal Delano',
    position: 'Tech Leader',
    linkedin: 'https://www.linkedin.com/in/cristobal-delano/',
    description: 'Cris es Ingeniero Industrial de la Universidad San Sebastián y siempre le apasionó el mundo del comercio digital y el retail. Su meta es llegar a una vida equilibrada que le permita desarrollarse profesionalmente junto a una familia y vida social estable. Sus hobbies son jugar videojuegos, el animé, y jugar con su perro.',
    freakFact: 'Es el “boomer” del equipo.',
    showDetails: true
  },
  {
    image: `${ASSETS_URL}/us/our-team/team/Vale_Echeverria.webp`,
    name: 'Valerie Echeverría',
    position: 'Asistente comercial',
    linkedin: 'https://www.linkedin.com/in/valerie-echeverr%C3%ADa-v%C3%A1squez-812047273/',
    description: 'La Vale es estudiante de Ingeniería Comercial mención en Administración de empresas en la Universidad de Chile, cursando su 4to año de carrera. Su sueño es viajar y hacer un voluntariado en algún otro país. Le gustan mucho los animales porque creció en una casa donde siempre hubo mascotas.',
    freakFact: 'Tiene una perrita de apoyo emocional.',
    showDetails: true
  },
  {
    image: `${ASSETS_URL}/us/our-team/team/Luciano_Encalada.webp`,
    name: 'Luciano Encalada',
    position: 'UX/UI Designer',
    linkedin: 'https://www.linkedin.com/in/luciano-encalada-9b9384149/',
    description: 'Luci es Diseñador Gráfico de la AIEP y siempre le apasionó la expresión gráfica. Actualmente es diseñador UX/UI y su sueño es trabajar como diseñador en la banda Crass. En sus tiempos libres lee, escribe, dibuja y aprende más sobre el arte.',
    freakFact: 'Lo han atropellado 4 veces.',
    showDetails: true
  },
  {
    image: `${ASSETS_URL}/us/our-team/team/Juanjo_Garcia.webp`,
    name: 'Juan José García',
    position: 'CEO',
    linkedin: 'https://www.linkedin.com/in/juan-jose-garcia-tellechea-9a4601117/',
    description: 'Juanjo es Ingeniero Civil Industrial de la Universidad Católica y siempre le gustó los desafíos, resolver problemas y el área de emprendimientos y negocios. Hoy es CEO de Pinflag y su sueño es crear una start-up que genere un impacto positivo en la sociedad. Sus hobbies son jugar pádel, basketball y vacacionar con la familia.',
    typicalQuote: 'Pregunta (Dwight en The Office)',
    showDetails: true
  },
  {
    image: `${ASSETS_URL}/us/our-team/team/Andrea_Llanos.webp`,
    name: 'Andrea Llanos',
    position: 'UX/UI Designer',
    linkedin: 'https://www.linkedin.com/in/andrea-llanos-698611228/',
    description: 'La Andre es publicista de la Universidad del Desarrollo y le apasiona la creatividad, el storytelling y el diseño. Actualmente ejerce como diseñadora UX/UI y su sueño es tener su propio proyecto creativo que sea un aporte a la sociedad. Le gusta la fotografía análoga, leer, ver la F1 y viajar.',
    freakFact: 'Le encanta el true crime.',
    showDetails: true
  },
  {
    image: `${ASSETS_URL}/us/our-team/team/Joche_Lopez.webp`,
    name: 'José Ignacio López',
    position: 'COO',
    linkedin: 'https://www.linkedin.com/in/jose-ignacio-lopez-salinas/',
    description: 'Joche es Ingeniero Civil Industrial de la Universidad Católica y cree que es una carrera que da las herramientas para resolver problemas complejos del futuro. Hoy es COO en Pinflag y su sueño es nunca perder la motivación para poder seguir creando productos que generen valor junto a un buen equipo. Le gusta acampar, tocar guitarra y cocinar.',
    freakFact: 'Quiere recorrer todos los parques nacionales de Chile.',
    showDetails: true
  },
  {
    image: `${ASSETS_URL}/us/our-team/team/Cami_Miranda.webp`,
    name: 'Camila Miranda',
    position: 'Community Manager',
    linkedin: 'https://www.linkedin.com/in/camila-miranda-soto/',
    description: 'La Cami viene de dedicar gran parte de su vida profesional al mundo hotelero, para luego darse cuenta que su pasión estaba en las comunicaciones. Nació y creció en la ciudad de Osorno pero ha vivido en muchos lugares desde ese entonces. Le gusta pintar en acuarela, cantar y hacer cosas de "soa".',
    freakFact: 'Trabajó en un hotel nudista en México.',
    showDetails: true
  },
  {
    image: `${ASSETS_URL}/us/our-team/team/Rosita_Munoz.webp`,
    name: 'Rosita Muñoz',
    position: 'Software Engineer',
    linkedin: 'https://www.linkedin.com/in/rosita-munoz-cabrera/',
    description: 'La Rosita es Ingeniera Civil en Computación de la Universidad Católica y le gusta resolver problemas con herramientas que puede entender y analizar. Ejerce como Ingeniera de Software y Frontend y su sueño es trabajar fuera del país desarrollando softwares. Le gusta andar en bicicleta, tejer y comer ramen.',
    freakFact: 'Puede tocarse la cabeza con el talón.',
    showDetails: true
  },
  {
    image: `${ASSETS_URL}/us/our-team/team/Javi_Ochoa.webp`,
    name: 'Javiera Ochoa',
    position: 'Software Engineer',
    linkedin: 'https://www.linkedin.com/in/javiera-ochoa-995085195/',
    description: 'La Javi es Ingeniera Industrial en Computación de la Universidad Católica y siempre le motivó poder llevar a la práctica sus conocimientos para solucionar problemas del mundo real. Hoy es Ingeniera de Software y su sueño es trabajar en el extranjero. En su tiempo libre ve series, baila jazz y estilos urbanos.',
    freakFact: 'Su segundo apellido es chino y la gente piensa que es por un grupo de Kpop.',
    showDetails: true
  },
  {
    image: `${ASSETS_URL}/us/our-team/team/Vicente_Perez.webp`,
    name: 'Vicente Pérez',
    position: 'Software Engineer',
    linkedin: 'https://www.linkedin.com/in/viperezg6/',
    description: 'Vicho es Ingeniero Civil Industrial en Computación de la Universidad Católica, carrera que le permite tener las herramientas para resolver problemas cotidianos. Hoy es Ingeniero de Software y su sueño es lanzar una start-up de educación para desarrollar el talento de cualquier estudiante en Chile. Le gusta el fútbol, Minecraft y el animé.',
    freakFact: 'Le encanta samsung y el Kpop.',
    showDetails: true
  },
  {
    image: `${ASSETS_URL}/us/our-team/circle-position.png`,
    name: 'Tu nombre',
    position: 'Tu cargo',
    linkedin: '',
    description: '',
    freakFact: '',
    showDetails: false
  },
  {
    image: `${ASSETS_URL}/us/our-team/team/Nicolas_Raimann.webp`,
    name: 'Nicolás Raimann',
    position: 'CSO',
    linkedin: 'https://www.linkedin.com/in/nicolás-raimann-544000b5/',
    description: 'Nico es Ingeniero Civil de la Universidad Católica y desde chico que le gustan los juegos de construcción y estrategia. Hoy es CSO de Pinflag y su sueño es trabajar en energías renovables y poder reutilizar todo al máximo. Sus hobbies son hacer trekking, andar en bicicleta, jugar pádel y bucear.',
    freakFact: 'A los 5 años se le rompió el diente y estuvo hasta los 10 con una paleta negra.',
    showDetails: true
  },
  {
    image: `${ASSETS_URL}/us/our-team/team/Javi_Ramos.webp`,
    name: 'Javier Ramos',
    position: 'Software Developer',
    linkedin: 'https://www.linkedin.com/in/javier-ramos-humeres/',
    description: 'Javier es Licenciado en Física de la Universidad Católica y es trombonista en Projazz. Su pasión por las ciencias, la música y la tecnología hizo que hoy ejerciera como Backend Developer y músico. Nunca deja de aprender y trata de mantener el equilibrio entre vida familiar y personal. En sus tiempos libres juega FIFA, lee y jardinea.',
    freakFact: 'Estuvo en la banda Moral Distraída por más de 10 años.',
    showDetails: true
  },
  {
    image: `${ASSETS_URL}/us/our-team/team/Edgar_Rincon.webp`,
    name: 'Edgar Rincón',
    position: 'Software Developer',
    linkedin: 'https://www.linkedin.com/in/edgar-rincon-ariza/',
    description: 'Ed es Ingeniero Químico de la Universidad X de Venezuela. Siempre fue bueno con las matemáticas y hoy ejerce como Frontend Developer. Su sueño es tener un restaurant y sus hobbies son los juegos online y las consolas.',
    freakFact: 'No le gusta el tomate.',
    showDetails: true
  },
  {
    image: `${ASSETS_URL}/us/our-team/team/Javi_Ruiz.webp`,
    name: 'Javier Ruiz',
    position: 'Tech Leader',
    linkedin: 'https://www.linkedin.com/in/javier-ignacio-ruiz-anriquez-575122163/',
    description: 'Javier es Ingeniero en Computación de la Universidad Católica y siempre quiso estudiar algo que le permitiera usar sus conocimientos para solucionar problemas en la actualidad. Hoy es Tech Leader y su sueño es ser CTO de un unicornio. Sus hobbies son hacer deporte, escuchar y hacer freestyle, bailar y ver series.',
    freakFact: 'Quiere hacerse tatuajes hasta ser como Duki.',
    showDetails: true
  },
  {
    image: `${ASSETS_URL}/us/our-team/team/Fran_Villarroel.webp`,
    name: 'Francisca Villarroel',
    position: 'Asistente comercial',
    linkedin: 'https://www.linkedin.com/in/francisca-villarroel-villalobos-55959a191/',
    description: 'La Fran es estudiante de Ingeniería Comercial, y cuando termine le gustaría cumplir uno de sus grandes sueños; conocer Irlanda. Le gustan mucho los gatos aunque es alérgica. Su pasatiempo favorito es tomar sol en la playa, salir a bailar con sus amigas y no puede pasar mucho tiempo sin comer su plato favorito, el ají de gallina.',
    freakFact: 'Hace mínimo 4 sudokus al día.',
    showDetails: true
  },
  {
    image: `${ASSETS_URL}/us/our-team/team/Jun_Wang.webp`,
    name: 'Jun Wang',
    position: 'Software Developer',
    linkedin: 'https://www.linkedin.com/in/junw4ng/',
    description: 'Jun es Desarrollador Full-Stack de Desafío Latam, siempre le gusto la programación y hoy ejerce como Software Developer. Su sueño es liderar un proyecto junto a un equipo y tener un e-commerce de café. Sus hobbies son ir al gimnasio y aprender todo sobre el café.',
    freakFact: 'Le encantan los corgis.',
    showDetails: true
  }
]

export default TEAM
